<template>
    <div>
        <section class="selfcare-title d-flex">
            <div>
                <a class="btn btn-link btn-sm" style="color: #000" @click="goBack()">
                    <svg fill="#000" class="material-design-icon__svg" width="15" height="15" viewBox="0 0 24 24">
                        <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"></path>
                    </svg>
                </a>
            </div>
            <div class="text-dark width-available text-center">
                <router-link to="/">
                    <img style="float: left; width: 110px" src="/img/chopLifeLogo.png" class="top-header-logo img-fluid"
                        alt="Chopbet Logo" />
                </router-link>
            </div>
        </section>

        <section class="p-2 terms">
            <div class="container">
                <h5>
                    <strong>
                        Betting Rules
                    </strong>
                </h5>

                <ul>
                    <h6> <strong>1. General Rules</strong></h6>
                    <li>
                        <strong>Eligibility</strong> : All participants must be 18 years or older to place predictions.
                        Choplife Gaming reserves the right to verify the age of participants.
                    </li>
                    <li>
                        <strong>Account Setup</strong> : Each participant must have a valid and verified account.
                        Duplicate accounts are not permitted and may result in disqualification.
                    </li>
                    <li>
                        <strong>Responsible Betting</strong> : We promote responsible gaming. Please ensure your
                        predictions are made with consideration of your entertainment and risk tolerance.
                    </li>

                    <h6> <strong>2. Types of Bets</strong></h6>
                    <li>
                        <strong>Match Outcome Predictions</strong> : Predict whether a team will win, lose, or draw in a
                        specific match.
                    </li>
                    <li>
                        <strong>Scoreline Predictions </strong> : Predict the exact scoreline of a match.
                    </li>
                    <li>
                        <strong>Goal Predictions </strong> : Predict the number of goals scored by either or both teams
                        during a match.
                    </li>
                    <li>
                        <strong>League Outcome Predictions </strong> : Predict the winner or top rankings of a football
                        league over an entire season.
                    </li>

                    <h6> <strong>3. How to Place Predictions</strong></h6>
                    <li>
                        <strong>Weekly Predictions </strong> : Participants can submit predictions for individual weekly
                        matches up until the official kick-off time of the first match.
                    </li>
                    <li>
                        <strong>League Predictions </strong> : Participants may predict outcomes for entire leagues or
                        tournaments. League predictions must be submitted before the start of the first match of the
                        competition.
                    </li>
                    <li>
                        <strong>Lock-in Time </strong> : Once a prediction is submitted and the match has started, it
                        cannot be changed or canceled.
                    </li>

                    <h6> <strong>4. Scoring System</strong></h6>
                    <li>
                        <strong>Correct Prediction Points </strong> : Points are awarded based on the accuracy of
                        predictions. Correct match outcome predictions (win, lose, draw) will receive a set number of
                        points.
                    </li>
                    <li>
                        <strong>Exact Scoreline Bonus </strong> : Additional points are awarded if the participant
                        correctly predicts the exact scoreline of a match.
                    </li>
                    <li>
                        <strong>Leaderboard Rankings </strong> : The leaderboard ranks participants based on the total
                        points accumulated from correct predictions over a week or season.
                    </li>

                    <h6> <strong>5. When this Policy Applies</strong></h6>
                    <p>This policy applies to all Chopbet betting services except: </p>
                    <li>
                        Information practices of other companies and organizations that advertise our services and
                        services offered by them or individuals
                    </li>

                </ul>
            </div>
        </section>
    </div>

</template>

<script>

export default {
    name: 'privacy',
    components: {},
    mounted: function () {
        //
    },
    methods: {
        //
    },

}
</script>

<style scoped>
.nav-item {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #fff;
    line-height: 1;
    gap: 0.7rem;
}

.nav-item .icon {
    position: relative;
}

body {
    font-family: Arial, sans-serif;
    padding: 20px;
}

h1,
h2 {
    margin-top: 30px;
    margin-bottom: 20px;
}

h2 {
    font-size: 1.5em;
}

p {
    margin-bottom: 10px;
}

.definition-list {
    margin-top: 20px;
}

.definition-list dt {
    font-weight: bold;
}

.definition-list dd {
    margin-bottom: 10px;
}
</style>